export const darkMode = {
  type: "dark",
  primary: {
    main: "#e35700",
  },
  secondary: {
    main: "rgb(220, 0, 78)",
  },
  background: {
    default: "#1e1e1f",
    paper: "#303030",
  },
  text: {
    primary: "#fff",
  },
};

export const lightMode = {
  type: "light",
  primary: {
    main: "#e35700",
  },
  secondary: {
    main: "rgb(220, 0, 78)",
  },
  background: {
    default: "lightgrey",
    paper: "#fff",
  },
  text: {
    primary: "#000",
  },
};
